import React, { useState } from "react";
import "../article-content/ArticleContent.scss";
import "./Toolkits.scss";
import "../close-button/CloseButton.scss";
import Arrow from "images/arrow-in-right.inline.svg";
import PrintIcon from "images/print-icon.inline.svg";
// maybe use gatsby's markdown features instead? (would have to use MDX to redefine html tags: https://www.gatsbyjs.com/docs/how-to/routing/customizing-components/)
import ReactMarkdown from "react-markdown";
import { motion, AnimatePresence } from "framer-motion";
import Img from "gatsby-image";

const RedArrowLeft = React.memo(() => <Arrow className={`arrow-left`} />);

const FilterMiniPill = React.memo(({ children, selected }) => {
    return (
        <span className={`toolkit-mini-pill${selected ? " selected" : ""}`}>
            {children}
        </span>
    );
});

const FilterButton = React.memo(({ children, filter, toggleFilter }) => {
    const [isActive, setisActive] = useState(false);
    const handleClick = () => {
        setisActive(!isActive);
        toggleFilter(filter);
    };

    return (
        <button
            className={`filter${isActive ? " active" : ""}`}
            onClick={handleClick}
        >
            {children}
        </button>
    );
});

const ToolkitsSelector = React.memo(
    ({ locale, data, translatedData, toolkitsFilters, strings }) => {
        // prepare Airtable data
        // sort out unique filter types
        const filterTypes = [
            ...new Set(
                toolkitsFilters.edges.map((edge) =>
                    edge.node.data.type.replace(/\W/gi, "_")
                )
            ),
        ];
        // sort out filter section questions and the contained filters and create a map of all the filters (for toggling)
        let allFiltersMap = new Map();
        const filtersSections = filterTypes.map((filterType) => ({
            title: strings[`${filterType}_question`],
            sectionFilters: toolkitsFilters.edges
                .filter(
                    (edge) =>
                        edge.node.data.type.replace(/\W/gi, "_") === filterType
                )
                .map((f) => {
                    allFiltersMap.set(f.node.recordId, {
                        isSet: false,
                        data: f.node.data,
                    });
                    return f.node;
                }),
        }));
        const getFilterName = (data) =>
            data[locale] ? data[locale] : data["en"];

        // filter toggling
        const [startedFiltering, setStartedFiltering] = useState(false);
        const [filters, setFilters] = useState(allFiltersMap);
        const toggleFilter = (filter) => {
            setStartedFiltering(true);
            // need to create a new map, otherwise react won't update the component
            setFilters(
                new Map(
                    filters.set(filter, {
                        data: filters.get(filter).data,
                        isSet: !filters.get(filter).isSet,
                    })
                )
            );
        };

        // get the filtered toolkits
        // TODO: refactor since Airtable data changed
        const numberOfFiltersSelected = [...filters].filter(
            (f) => filters.get(f[0]).isSet
        ).length;
        const filtered = data.edges.filter((entry) => {
            let show = 0;
            filterTypes.forEach((filterType) => {
                entry.node.data[filterType]?.forEach((filterId) => {
                    if (filters.get(filterId).isSet) {
                        show += 1;
                    }
                });
            });
            return numberOfFiltersSelected && show === numberOfFiltersSelected;
        });

        return (
            <div className={"toolkit-container"}>
                <div className={"toolkit-filters"}>
                    {filtersSections.map((section, sid) => (
                        <section key={sid}>
                            <h3>{section.title}</h3>
                            {[...section.sectionFilters].map((f) => {
                                return (
                                    <FilterButton
                                        key={f.recordId}
                                        filter={f.recordId}
                                        toggleFilter={toggleFilter}
                                    >
                                        {getFilterName(f.data)}
                                    </FilterButton>
                                );
                            })}
                        </section>
                    ))}

                    {filtered.length > 0 && (
                        <AnimatePresence>
                            <motion.button
                                variants={{
                                    visible: {
                                        opacity: 1,
                                    },
                                    hidden: {
                                        opacity: 0,
                                    },
                                }}
                                transition={{ ease: "easeIn", duration: 0.5 }}
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                                key="print-button"
                                className="filter print-button"
                                onClick={() => {
                                    window.print();
                                }}
                            >
                                <PrintIcon />
                            </motion.button>
                        </AnimatePresence>
                    )}
                </div>

                {filtered.length > 0 && (
                    <AnimatePresence>
                        <motion.div
                            variants={{
                                visible: {
                                    scaleX: 1,
                                },
                                hidden: {
                                    scaleX: 0,
                                },
                            }}
                            transition={"easeIn"}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            key={"separator"}
                        >
                            <hr />
                        </motion.div>
                    </AnimatePresence>
                )}

                {[...filters.values()].filter((f) => f.isSet).length === 0 && (
                    <AnimatePresence>
                        <motion.div
                            variants={{
                                visible: {
                                    opacity: 1,
                                },
                                hidden: {
                                    opacity: 0,
                                },
                            }}
                            transition={"easeIn"}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            key={"prompt-filters"}
                            className={"warn"}
                        >
                            {strings.Prompt_for_filters}
                        </motion.div>
                    </AnimatePresence>
                )}

                {startedFiltering &&
                    filtered.length === 0 &&
                    [...filters.values()].filter((f) => f.isSet).length !==
                        0 && (
                        <>
                            <hr />
                            <div className={"warn"}>
                                {strings.Empty_results}
                            </div>
                        </>
                    )}

                <AnimatePresence>
                    {filtered.map((entry, index) => (
                        <motion.div
                            variants={{
                                visible: {
                                    opacity: 1,
                                    scaleY: 1,
                                    height: "auto",
                                },
                                hidden: {
                                    opacity: 0,
                                    scaleY: 0,
                                    height: 0,
                                },
                            }}
                            transition={"easeIn"}
                            style={{ originY: "top" }}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            key={`toolkit-${entry.node.data.Name_of_activity}`}
                        >
                            <SingleToolkit
                                locale={locale}
                                filters={filters}
                                translatedData={translatedData}
                                entry={entry}
                                filterTypes={filterTypes}
                            />
                        </motion.div>
                    ))}
                </AnimatePresence>
            </div>
        );
    }
);

const SingleToolkit = ({
    locale,
    filters,
    translatedData,
    entry,
    filterTypes,
}) => {
    let title = entry.node.data.Name_of_activity,
        content = entry.node.data.Content;
    if (locale !== "en") {
        let translatedNode = translatedData?.edges?.filter(
            (edge) =>
                edge.node.data.English_original?.indexOf(entry.node.recordId) >
                -1
        )[0]?.node;
        title = translatedNode?.data?.Name_of_activity
            ? translatedNode.data.Name_of_activity
            : title;
        // airtable sometimes sets empty fields with "\n" (!...)
        content =
            translatedNode?.data?.Content &&
            translatedNode?.data?.Content !== "\n"
                ? translatedNode.data.Content
                : content;
    }

    const getFilterName = (id) =>
        filters.get(id).data[locale]
            ? filters.get(id).data[locale]
            : filters.get(id).data["en"];

    return (
        <>
            <h3 className={"toolkit-title"}>{title}</h3>

            <div className={"toolkit-mini-pill-container"}>
                {filterTypes.map((filterType) => (
                    <div key={filterType} className={"mini-pill-section"}>
                        {entry.node.data[filterType]
                            ?.sort((a, b) =>
                                getFilterName(a).localeCompare(getFilterName(b))
                            )
                            .map((filterId, index) => (
                                <FilterMiniPill
                                    key={index}
                                    selected={filters.get(filterId).isSet}
                                >
                                    {getFilterName(filterId)}
                                </FilterMiniPill>
                            ))}
                    </div>
                ))}
            </div>

            {entry.node.data?.Feature_Image?.localFiles?.[0]?.childImageSharp
                ?.fluid && (
                <Img
                    fluid={
                        entry.node.data?.Feature_Image?.localFiles[0]
                            .childImageSharp.fluid
                    }
                />
            )}
            <section className={"toolkit"}>
                <ReactMarkdown
                    components={{
                        h1: "h4",
                        h2: "h5",
                        h3: "h6",
                        a: ({ node, ...props }) => (
                            // eslint-disable-next-line
                            <a target="_blank" rel="noopener" {...props} />
                        ),
                    }}
                >
                    {content}
                </ReactMarkdown>
            </section>
        </>
    );
};

const Toolkits = React.memo(
    ({
        locale,
        strings,
        isUntranslated,
        toolkitsFilters,
        toolkitsEnglishData,
        toolkitsTranslatedData,
    }) => {
        const [selectingFilters, setSelectingFilters] = useState(false);

        return (
            <div className="article-template">
                <AnimatePresence>
                    {selectingFilters && (
                        <motion.button
                            variants={{
                                visible: {
                                    opacity: 1,
                                    transition: {
                                        ease: "circIn",
                                        delay: 0.5,
                                    },
                                },
                                hidden: {
                                    opacity: 0,
                                    transition: {
                                        ease: "circOut",
                                    },
                                },
                            }}
                            initial="hidden"
                            animate={selectingFilters ? "visible" : "hidden"}
                            exit="hidden"
                            key={`toolkit-back-button`}
                            className={"toolkit-return-link"}
                            onClick={() => setSelectingFilters(false)}
                        >
                            <RedArrowLeft />
                        </motion.button>
                    )}
                </AnimatePresence>
                <div className={"articleContainer"}>
                    <article>
                        <div className="top-content">
                            <div className="article_content">
                                <AnimatePresence exitBeforeEnter>
                                    {selectingFilters ? (
                                        <motion.div
                                            variants={{
                                                visible: {
                                                    opacity: 1,
                                                    x: 0,
                                                },
                                                hidden: {
                                                    opacity: 0,
                                                    x: "100vw",
                                                },
                                            }}
                                            transition={"circIn"}
                                            initial="hidden"
                                            animate="visible"
                                            exit="hidden"
                                            key={`toolkit-selector-page`}
                                        >
                                            <div
                                                className={"toolkit-page-title"}
                                            >
                                                {strings.Page_name}
                                            </div>

                                            <ToolkitsSelector
                                                locale={locale}
                                                data={toolkitsEnglishData}
                                                translatedData={
                                                    toolkitsTranslatedData
                                                }
                                                toolkitsFilters={
                                                    toolkitsFilters
                                                }
                                                strings={strings}
                                            />
                                        </motion.div>
                                    ) : (
                                        <motion.div
                                            variants={{
                                                visible: {
                                                    opacity: 1,
                                                    x: 0,
                                                },
                                                hidden: {
                                                    opacity: 0,
                                                    x: "-100vw",
                                                },
                                            }}
                                            transition={"circIn"}
                                            initial="hidden"
                                            animate="visible"
                                            exit="hidden"
                                            key={`toolkit-initial-page`}
                                            className="article_content__col article_content__col--left"
                                        >
                                            {isUntranslated && (
                                                <div
                                                    className={
                                                        "warn no-translation"
                                                    }
                                                >
                                                    {
                                                        strings.Untranslated_message
                                                    }
                                                </div>
                                            )}
                                            <ReactMarkdown
                                                components={{
                                                    h1: ({
                                                        node,
                                                        ...props
                                                    }) => (
                                                        // eslint-disable-next-line
                                                        <h1
                                                            className="article_content__title"
                                                            {...props}
                                                        />
                                                    ),
                                                    h2: "h3",
                                                }}
                                            >
                                                {strings.Intro}
                                            </ReactMarkdown>

                                            <button
                                                aria-label="choose toolkits"
                                                className={
                                                    "choose-activity-button"
                                                }
                                                onClick={() => {
                                                    setSelectingFilters(true);
                                                    if (
                                                        typeof window !==
                                                        `undefined`
                                                    ) {
                                                        window.scrollTo(0, 0);
                                                    }
                                                }}
                                            >
                                                {strings.Button}
                                                <Arrow className={`arrow`} />
                                            </button>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        );
    }
);

export default Toolkits;
